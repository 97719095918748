import React, {useState, useEffect} from 'react';
import {Alert, Modal, Button, TextInput} from 'flowbite-react';
import {EyeIcon, EyeOffIcon, ClipboardIcon, ClipboardCheckIcon} from 'lucide-react';
import CreateUserForm from '../components/CreateUserForm';
import {fetchUserData, createUser} from '../services/api';

const CreateUserPage = () => {
    const [message, setMessage] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [createdUser, setCreatedUser] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordCopied, setPasswordCopied] = useState(false);

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const userData = await fetchUserData();
                setCurrentUser(userData);
            } catch (error) {
                console.error('Error fetching current user data:', error);
            }
        };

        getCurrentUser();
    }, []);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage(null);
            }, 5000); // Message will disappear after 5 seconds

            return () => clearTimeout(timer);
        }
    }, [message]);

    const handleUserCreated = async (userData) => {
        try {
            const newUser = await createUser(userData);
            setCreatedUser(newUser);
            setShowConfirmation(true);
            setPasswordCopied(false);
        } catch (error) {
            console.error('Error creating user:', error);
            if (error.response && error.response.data) {
                if (error.response.data.detail) {
                    setMessage({type: 'failure', content: error.response.data.detail});
                } else if (typeof error.response.data === 'object') {
                    const errorMessages = Object.entries(error.response.data)
                        .map(([key, value]) => `${key}: ${value.join(', ')}`)
                        .join('; ');
                    setMessage({type: 'failure', content: errorMessages});
                } else {
                    setMessage({type: 'failure', content: 'An unexpected error occurred. Please try again.'});
                }
            } else {
                setMessage({type: 'failure', content: 'Failed to create user. Please try again.'});
            }
        }
    };

    const handleComplete = () => {
        if (passwordCopied) {
            setShowConfirmation(false);
            setCreatedUser(null);
            setMessage({type: 'success', content: 'User created successfully!'});
        }
    };

    const handleCopyPassword = () => {
        if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
            navigator.clipboard.writeText(createdUser.password)
                .then(() => {
                    setPasswordCopied(true);
                })
                .catch((err) => {
                    console.error('Failed to copy password: ', err);
                    fallbackCopyTextToClipboard(createdUser.password);
                });
        } else {
            fallbackCopyTextToClipboard(createdUser.password);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            setPasswordCopied(true);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCloseModal = () => {
        if (passwordCopied) {
            setShowConfirmation(false);
            setCreatedUser(null);
            setMessage({type: 'success', content: 'User created successfully!'});
        } else {
            alert('Please copy the password before closing this modal.');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Create User</h1>

            {message && (
                <Alert color={message.type === 'success' ? 'success' : 'failure'} className="mb-4">
                    {message.content}
                </Alert>
            )}

            <div className="max-w-md mx-auto">
                <CreateUserForm
                    onSubmit={handleUserCreated}
                    currentUserType={currentUser?.user_type}
                    onComplete={handleComplete}
                />
            </div>

            <Modal show={showConfirmation} onClose={handleCloseModal} dismissible={true}>
                <Modal.Header>User Created Successfully</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p>The following user has been created:</p>
                        <p><strong>Username:</strong> {createdUser?.username}</p>
                        <p><strong>Email:</strong> {createdUser?.email}</p>
                        <p><strong>User Type:</strong> {createdUser?.user_type}</p>
                        <p><strong>State:</strong> {createdUser?.state}</p>
                        <p><strong>City:</strong> {createdUser?.city}</p>
                        <div className="flex items-center space-x-2">
                            <strong>Password:</strong>
                            <TextInput
                                type={showPassword ? "text" : "password"}
                                value={createdUser?.password}
                                readOnly
                                className="flex-grow"
                            />
                            <Button onClick={toggleShowPassword}>
                                {showPassword ? <EyeOffIcon size={20}/> : <EyeIcon size={20}/>}
                            </Button>
                            <Button onClick={handleCopyPassword} disabled={passwordCopied}>
                                {passwordCopied ? <ClipboardCheckIcon size={20}/> : <ClipboardIcon size={20}/>}
                            </Button>
                        </div>
                        {!passwordCopied && (
                            <p className="text-red-500">Please copy the password before closing this modal.</p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateUserPage;