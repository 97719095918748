import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Card } from 'flowbite-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { fetchStateMetadata, createStateMetadata, deleteStateMetadata } from '../services/api';
import { XCircle } from 'lucide-react';
import ReactHtmlParser from 'html-react-parser';

// State abbreviation mapping
const states_dict = {
  "Arizona": "AZ",
  "Georgia": "GA",
  "Michigan": "MI",
  "Nevada": "NV",
  "North Carolina": "NC",
  "Pennsylvania": "PA",
  "Wisconsin": "WI"
};

// Custom toolbar configuration
const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link'],  // Keep the link option
    [{ 'color': [] }, { 'background': [] }], // Add color and background color options
    [{ 'align': [] }],
    ['clean']
  ],
};

const StateMetadataModal = ({ show, onClose, userType, userState }) => {
  const [metadata, setMetadata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [selectedState, setSelectedState] = useState('');

  const canCreateAndRead = ['admin', 'national_director', 'state_director'].includes(userType);
  const isAdminOrDirector = ['admin', 'national_director'].includes(userType);
  const showState = isAdminOrDirector;

  useEffect(() => {
    if (show) {
      loadMetadata();
    }
  }, [show]);

  const loadMetadata = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchStateMetadata();
      const sortedMetadata = (data.results || []).sort((a, b) =>
        new Date(b.last_updated) - new Date(a.last_updated)
      );
      setMetadata(sortedMetadata);
    } catch (error) {
      setMetadata([]);
      setError('Failed to load messages. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateNote = async () => {
    let stateToUse;
    if (isAdminOrDirector) {
      stateToUse = selectedState;
      if (!stateToUse) {
        setError('Please select a state.');
        return;
      }
    } else {
      stateToUse = userState;
    }

    if (!newNote.trim()) {
      setError('Please enter a message.');
      return;
    }

    try {
      await createStateMetadata({ state: stateToUse, state_notes: newNote.trim() });
      setNewNote('');
      if (isAdminOrDirector) {
        setSelectedState('');
      }
      setShowCreateForm(false);
      loadMetadata();
    } catch (error) {
      setError('Failed to create message. Please try again.');
    }
  };

  const handleDeleteNote = async (id) => {
    try {
      await deleteStateMetadata(id);
      loadMetadata();
    } catch (error) {
      setError('Failed to delete message. Please try again.');
    }
  };

  const cleanCreatedBy = (createdBy) => {
    return createdBy.replace(/[-\s]+$/, '');
  };

  // Adjust the modal and editor layout dynamically
  const adjustLinkDialogPosition = () => {
    const linkDialog = document.querySelector('.ql-tooltip');
    if (linkDialog) {
      const modal = document.querySelector('.modal-content');
      if (modal && linkDialog.offsetLeft + linkDialog.offsetWidth > modal.offsetWidth) {
        linkDialog.style.left = `${modal.offsetWidth - linkDialog.offsetWidth}px`;
      }
    }
  };

  useEffect(() => {
    adjustLinkDialogPosition();
  }, [newNote]);

  return (
    <Modal show={show} onClose={onClose} size="3xl">
      <Modal.Header>State Messages</Modal.Header>
      <Modal.Body>
        {isLoading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}

        {!isLoading && (
          <div className="flex flex-col space-y-4 max-h-[60vh] overflow-y-auto mb-4">
            {metadata.map((item) => (
              <Card key={item.id} className="w-full relative">
                {canCreateAndRead && (
                  <button
                    onClick={() => handleDeleteNote(item.id)}
                    className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                  >
                    <XCircle size={20} />
                  </button>
                )}
                {showState && (
                  <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {states_dict[item.state] || item.state}
                  </h5>
                )}
                <div className="font-normal text-gray-700 dark:text-gray-400">
                  {ReactHtmlParser(item.state_notes)}
                </div>
                <p className="text-sm text-gray-500">
                  Last updated: {new Date(item.last_updated).toLocaleString()}
                </p>
                <p className="text-sm text-gray-500">
                  Created by: {cleanCreatedBy(item.created_by)}
                </p>
              </Card>
            ))}
          </div>
        )}

        {canCreateAndRead && !showCreateForm && (
          <Button onClick={() => setShowCreateForm(true)} size="md">Create New Message</Button>
        )}

        {showCreateForm && (
          <div className="mt-4">
            {isAdminOrDirector ? (
              <Select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="mb-2 text-lg"
                style={{ height: '48px' }}
              >
                <option value="">Select a state</option>
                {Object.entries(states_dict).map(([stateName, stateCode]) => (
                  <option key={stateCode} value={stateCode}>{stateName}</option>
                ))}
              </Select>
            ) : (
              <p className="mb-2">Creating message for state: {states_dict[userState] || userState}</p>
            )}
            <div className="mb-4 quill-editor-wrapper">
              <ReactQuill
                theme="snow"
                value={newNote}
                onChange={setNewNote}
                modules={modules}
                style={{ height: '200px', fontSize: '16px' }}
              />
            </div>
            <div className="flex justify-start space-x-2">
              <Button onClick={handleCreateNote} size="md">Add Note</Button>
              <Button color="light" onClick={() => setShowCreateForm(false)} size="md">Cancel</Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default StateMetadataModal;

