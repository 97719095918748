// src/components/withUserTypeValidation.js
import React, { useEffect, useState } from 'react';
import { fetchUserData } from '../services/api';

const withUserTypeValidation = (WrappedComponent) => {
  return (props) => {
    const [userType, setUserType] = useState('');

    useEffect(() => {
      const getUserData = async () => {
        try {
          const data = await fetchUserData();
          setUserType(data.user_type);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      getUserData();
    }, []);

    return <WrappedComponent {...props} userType={userType} />;
  };
};

export default withUserTypeValidation;