import React from 'react';
import { Modal, Button } from 'flowbite-react';

const AddressModal = ({ show, onClose, address }) => {
    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>Address Information</Modal.Header>
            <Modal.Body>
                <p>{address}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddressModal;