const originalConsoleError = console.error;

console.error = function (...args) {
    if (
        args[0] &&
        args[0].includes &&
        args[0].includes('ResizeObserver loop completed with undelivered notifications.')
    ) {
        return;
    }
    originalConsoleError.apply(console, args);
};

export const ignoreResizeObserverErrors = () => {
    window.addEventListener('error', (event) => {
        if (event.message === 'ResizeObserver loop completed with undelivered notifications.') {
            event.stopImmediatePropagation();
        }
    });
};