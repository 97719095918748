import React from 'react';
import {Flowbite} from 'flowbite-react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import createAxiosInstance from './services/axiosInstance';
import LoginPage from './pages/LoginPage';
import LeadsPage from './pages/LeadsPage';
import Layout from './components/Layout';
import CreateUserPage from "./pages/CreateUserPage";
import UsersPage from './pages/UsersPage';
import ProtectedRoute from './components/ProtectedRoute';
import {logout} from './services/auth';

const App = () => {
    const axiosInstance = createAxiosInstance(logout);

    return (
        <Flowbite>
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Layout handleLogout={logout}/>
                            </ProtectedRoute>
                        }
                    >
                        <Route path="/leads" element={<LeadsPage axiosInstance={axiosInstance}/>}/>
                        <Route
                            path="/create-user"
                            element={
                                <ProtectedRoute allowedRoles={['admin', 'national_director', 'state_director']}>
                                    <CreateUserPage/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <ProtectedRoute allowedRoles={['admin', 'national_director', 'state_director']}>
                                    <UsersPage/>
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to="/leads" replace/>}/>
                    </Route>
                    <Route path="*" element={<Navigate to="/login" replace/>}/>
                </Routes>
            </Router>
        </Flowbite>
    );
};

export default App;