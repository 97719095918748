import React, {useState, useEffect} from 'react';
import {fetchLeads} from '../services/api';
import {Table} from 'flowbite-react';
import withUserTypeValidation from './withUserTypeValidation';
import FilterForm from './FilterForm';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import PaginationSection from './PaginationSection';
import PhoneModal from './PhoneModal';
import AssignModal from './AssignModal';
import ConfirmationModal from './ConfirmationModal';
import StatusModal from './StatusModal';
import BallotChaserModal from './BallotChaserModal';
import AssignNearestLeadsModal from './AssignNearestLeadsModal';
import AddressModal from './AddressModal';

const LeadsTable = ({userType}) => {
    const [leads, setLeads] = useState([]);
    const [pagination, setPagination] = useState({});
    const [filters, setFilters] = useState({state: '', city: '', zip_code: '', assigned_to: ''});
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [checkboxesLocked, setCheckboxesLocked] = useState(true);

    // Modal states
    const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showBallotChaserModal, setShowBallotChaserModal] = useState(false);

    // Modal data states
    const [selectedPhones, setSelectedPhones] = useState({});
    const [selectedBallotChaser, setSelectedBallotChaser] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const [showAssignNearestModal, setShowAssignNearestModal] = useState(false);
    const [selectedLeadId, setSelectedLeadId] = useState(null);

    useEffect(() => {
        fetchLeads(filters, currentPage).then((data) => {
            setLeads(data.results);
            setPagination({
                count: data.count,
                next: data.next,
                previous: data.previous,
            });
        });
    }, [filters, currentPage]);

    useEffect(() => {
        if (selectAll) {
            setSelectedRows(leads.map(lead => lead.id));
        } else {
            setSelectedRows([]);
        }
    }, [selectAll, leads]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleFilterChange = (newFilters, reset = false) => {
        setFilters(newFilters);
        setCurrentPage(1);
        if (reset) {
            setSelectedRows([]);
            setSelectAll(false);
        }
        setCheckboxesLocked(newFilters.assigned_to !== 'none' && userType !== 'ballot_chaser');
    };

    const openPhoneModal = (phones) => {
        setSelectedPhones(phones);
        setShowPhoneModal(true);
    };

    const openStatusModal = (leadId) => {
        setSelectedLeadId(leadId);
        setShowStatusModal(true);
    };

    const openBallotChaserModal = (ballotChaser) => {
        setSelectedBallotChaser(ballotChaser);
        setShowBallotChaserModal(true);
    };

    const openAssignModal = () => {
        if (filters.assigned_to === 'none' && selectedRows.length > 0) {
            setShowAssignModal(true);
        }
    };

    const handleAssignSuccess = (message) => {
        setConfirmationMessage(message);
        setShowConfirmationModal(true);
        // Refresh leads data
        fetchLeads(filters, currentPage).then((data) => {
            setLeads(data.results);
        });
    };
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');

    const openAssignNearestModal = () => {
        setShowAssignNearestModal(true);
    };

    const handleAssignNearestSuccess = (message) => {
        setConfirmationMessage(message);
        setShowConfirmationModal(true);
        // Refresh leads data
        fetchLeads(filters, currentPage).then((data) => {
            setLeads(data.results);
        });
    };

    const handleStatusUpdate = () => {
        // Refresh the leads data to reflect the updated status
        fetchLeads(filters, currentPage).then((data) => {
            setLeads(data.results);
        });
    };

    const openAddressModal = (address) => {
        setSelectedAddress(address);
        setShowAddressModal(true);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Leads</h1>
            <FilterForm
                onFilterChange={handleFilterChange}
                userType={userType}
                openAssignNearestModal={openAssignNearestModal}
            />
            <div className="overflow-x-auto">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                        <Table hoverable>
                            <TableHeader
                                userType={userType}
                                selectAll={selectAll}
                                setSelectAll={setSelectAll}
                                checkboxesLocked={checkboxesLocked}
                            />
                            <Table.Body className="divide-y">
                                {leads.map((lead) => (
                                    <TableRow
                                        key={lead.id}
                                        lead={lead}
                                        userType={userType}
                                        selectedRows={selectedRows}
                                        setSelectedRows={setSelectedRows}
                                        checkboxesLocked={checkboxesLocked}
                                        openPhoneModal={openPhoneModal}
                                        openStatusModal={openStatusModal}
                                        openBallotChaserModal={openBallotChaserModal}
                                        openAddressModal={openAddressModal}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
            <PaginationSection
                currentPage={currentPage}
                totalPages={Math.max(1, Math.ceil((pagination.count || 0) / 10))}
                onPageChange={handlePageChange}
                userType={userType}
                selectedRows={selectedRows}
                filters={filters}
                openAssignModal={openAssignModal}
            />
            <PhoneModal
                show={showPhoneModal}
                onClose={() => setShowPhoneModal(false)}
                phones={selectedPhones}
            />
            <AssignModal
                show={showAssignModal}
                onClose={() => setShowAssignModal(false)}
                selectedRows={selectedRows}
                leads={leads}
                onAssignSuccess={handleAssignSuccess}
            />
            <ConfirmationModal
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                message={confirmationMessage}
            />
            <StatusModal
                show={showStatusModal}
                onClose={() => setShowStatusModal(false)}
                leadId={selectedLeadId}
                onStatusUpdate={handleStatusUpdate}
            />
            <BallotChaserModal
                show={showBallotChaserModal}
                onClose={() => setShowBallotChaserModal(false)}
                ballotChaser={selectedBallotChaser}
            />
            <AssignNearestLeadsModal
                show={showAssignNearestModal}
                onClose={() => setShowAssignNearestModal(false)}
                onAssignSuccess={handleAssignNearestSuccess}
            />
            <AddressModal
                show={showAddressModal}
                onClose={() => setShowAddressModal(false)}
                address={selectedAddress}
            />
        </div>
    );
};

const LeadsTableWithValidation = withUserTypeValidation(LeadsTable);

export default LeadsTableWithValidation;