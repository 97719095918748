import React, { useState } from 'react';
import { Modal, Button, Checkbox, Label } from 'flowbite-react';

const TermsModal = ({ show, onClose, onAccept, error }) => {
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [isCheckedAgreement, setIsCheckedAgreement] = useState(false);

  const handleCheckboxChangeTerms = () => {
    setIsCheckedTerms(!isCheckedTerms);
  };

  const handleCheckboxChangeAgreement = () => {
    setIsCheckedAgreement(!isCheckedAgreement);
  };

  const handleAccept = () => {
    if (isCheckedTerms && isCheckedAgreement) {
      onAccept();
    }
  };

  return (
    <Modal show={show} onClose={onClose} size="xl">
      <Modal.Header>
        Terms and Conditions
      </Modal.Header>
      <Modal.Body>
        <div className="h-96 overflow-y-scroll mb-4 p-4 bg-gray-50 rounded">
          <h2 className="text-xl font-bold mb-4">VOLUNTEER PARTICIPATION AND CONSENT AGREEMENT</h2>
          <h3 className="text-lg font-bold mb-2">CATHOLICS FOR CATHOLICS – BALLOT CHASING INITIATIVE</h3>

          <p className="mb-4">I am a volunteer participant in Catholics for Catholics'("C4C") activity known as the Ballot Chasing Initiative ("Initiative"). I agree and consent to the following.</p>

          <h3 className="text-lg font-bold mb-2">A. Ballot Chasing Initiative Participation</h3>

          <ol className="list-decimal pl-6 mb-4">
            <li className="mb-2">The nature and extent of C4C's Initiative has been sufficiently explained to me. I acknowledge that the Initiative involves efforts to seek voter registration in the 2024 national election and therefore involve physical activity, knocking on people's doors, other personal engagement, and potential weather-related issues. I acknowledge that I am sufficiently mature and physically able to participate in such Initiative. To the best of my knowledge, I have no physical condition that will be aggravated by the Initiative or that will impair my ability to participate in the Initiative. In particular, I acknowledge the risk of serious injury or other harm from the Initiative.</li>
            <li className="mb-2">I agree that my participation in the Initiative will be consistent with C4C's mission, Catholic identity, and value. C4C is a Section 501(c)(4) nonprofit that provides educational resources about Catholic doctrine including materials addressing Catholic leaders' positions on public policy, legislation, and other matters affecting social welfare, as well as engaging in related activities that similarly promote social welfare based on Catholic doctrinal teachings. C4C affirms Catholicism as the bedrock for its activities, consistent with the Roman Catholic Church's teachings including the Bible, Divine Revelation, and Sacred Tradition, as interpreted by the Magisterium. All activities conform with and adhere to the Magisterial teaching of the Catholic Church.</li>
            <li className="mb-2">I agree to fully cooperate with C4C personnel in order to minimize any risks, including but not limited to prompt disclosure of any future health-related issues related to my full ability to participate in the Initiative, postponement or cancellation of Initiative activities in the event of adverse weather-related conditions, and compliance with other C4C guidelines and directives.</li>
            <li className="mb-2">
              As a volunteer, I will not engage in any of the following:
              <ul className="list-disc pl-6 mt-2">
                <li>Engage in public action that is in conflict with the mission statement of C4C and which might cause potential harm or scandal to C4C;</li>
                <li>Use social media in a manner harmful to C4C including posting proprietary or other confidential information or making statements inconsistent with C4C's belief and mission;</li>
                <li>Publicly represent C4C without prior approval of the organization's administrative staff; and</li>
                <li>Use profane and abusive language and/or disruptive behavior that is dangerous to self and others.</li>
              </ul>
            </li>
            <li className="mb-2">From time to time, I may incur expenses in activities designed to help or assist C4C in fulfilling its purposes. I understand that unless otherwise agreed to by C4C in writing, I will be responsible for the funding of such activities.</li>
          </ol>

          <h3 className="text-lg font-bold mb-2">B. Release</h3>

          <p className="mb-4">I hereby release and discharge C4C's directors, officers, other volunteers, employees, and other agents and representatives (collectively "C4C" here) from any and all liability, claims, demands, or causes of action that I may hereafter have for injuries or damages arising out of my participation in the Initiative, except for C4C's gross negligence or intentional misconduct in connection with the Initiative. I further agree not to sue or make claim against C4C for damages or other losses sustained as a result of my participation in the Initiative, including but not limited to the above-listed risks. I also agree to indemnify and hold C4C harmless from all claims, settlements, judgments and costs, including but not limited to attorneys' fees, and to reimburse C4C for any expenses whatsoever incurred in connection with any action brought by me or on my behalf as a result of participating in the Initiative.</p>

          <h3 className="text-lg font-bold mb-2">C. Confidentiality</h3>

          <p className="mb-4">I agree that by the very nature of the services to be performed for C4C, I may become aware of C4C's confidential information, confidentiality of which is of the utmost importance to C4C's ability to carry out the Initiative. Such confidential information, which may be identified from time to time by C4C, may include but is not limited to information about the Initiative: donor information; employee information; financial, legal, promotional, or operational information; fundraising information; other private information from meetings; strategic planning information; research; methods, procedures, and other internal organizational and operational information; and photographs, videos, and other media not for third-party disclosure (collectively, "Confidential Information"). I shall not disclose Confidential Information without receiving express written permission from C4C. I shall take all steps reasonably necessary to protect the secrecy of the Confidential Information at all times and without limit as to duration, and to prevent it from disclosure to unauthorized persons or from otherwise being released into the public domain. Upon receipt of a written request from C4C, I shall immediately remove, delete, destroy, or return to C4C, all documents, whether in paper or electronic form, and other tangible materials containing Confidential Information that are in my possession.</p>

          <h3 className="text-lg font-bold mb-2">D. Consent</h3>

          <ol className="list-decimal pl-6 mb-4">
            <li className="mb-2">I hereby give consent to C4C to authorize treatment on my behalf in the event of an emergency or illness, as provided below. I agree to reimburse C4C for any and all costs incurred for such care.</li>
            <li className="mb-2">I hereby grant permission for the use of my name, photo, and/or video footage in connection with the Initiative, including use on C4Cs website.</li>
          </ol>

          <p className="font-bold mt-4">End of Terms and Conditions</p>
          <div className="flex items-center mt-4">
            <Checkbox
              id="acceptTerms"
              checked={isCheckedTerms}
              onChange={handleCheckboxChangeTerms}
            />
            <Label htmlFor="acceptTerms" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              I have read and agree to the terms and conditions
            </Label>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <Checkbox
            id="acceptAgreement"
            checked={isCheckedAgreement}
            onChange={handleCheckboxChangeAgreement}
          />
          <Label htmlFor="acceptAgreement" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            I agree with this Volunteer Participation and Consent Agreement in order to proceed to the Catholics for Catholics Ballot Chasing Portal
          </Label>
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleAccept} disabled={!isCheckedTerms || !isCheckedAgreement}>
          Accept
        </Button>
        <Button color="gray" onClick={onClose}>
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;