import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { createAxiosInstance } from '../services/axiosInstance';
import TermsModal from './TermsModal';
import { acceptTerms } from '../services/auth';

const axiosInstance = createAxiosInstance(() => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location.href = '/login';
});

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const [userRole, setUserRole] = useState(null);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await axiosInstance.get('/api/users/me/');
        const userData = response.data;
        setUserRole(userData.user_type);
        setHasAcceptedTerms(userData.terms_accepted);
        if (!userData.terms_accepted) {
          setShowTermsModal(true);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, []);

  const handleAcceptTerms = async () => {
    try {
      await acceptTerms();
      setHasAcceptedTerms(true);
      setShowTermsModal(false);
    } catch (error) {
      console.error('Error accepting terms:', error);
      setError('Failed to accept terms. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!hasAcceptedTerms) {
    return (
      <>
        <TermsModal
          show={showTermsModal}
          onClose={() => {
            setShowTermsModal(false);
            window.location.href = '/login';
          }}
          onAccept={handleAcceptTerms}
          error={error}
        />
        <Navigate to="/login" state={{ from: location, needTerms: true }} replace />
      </>
    );
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole) && userRole !== null) {
    return <Navigate to="/leads" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;