import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Label, TextInput, Select } from 'flowbite-react';
import { updateUser, updatePassword, checkUsernameAvailability } from '../services/api';
import { states_dict, citiesByState } from '../constants/locationData';
import { Loader } from "@googlemaps/js-api-loader";

const SettingsModal = ({ show, onClose, user, onUpdate }) => {
    const [formData, setFormData] = useState({
        username: user.username,
        email: user.email,
        state: user.state,
        city: user.city,
        zip_code: user.zip_code,
        phone: user.phone,
        address: user.address || '',
        lat: user.lat || null,
        lon: user.lon || null
    });
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState('');
    const [cities, setCities] = useState([]);
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);

    useEffect(() => {
        setFormData({
            username: user.username,
            email: user.email,
            state: user.state,
            city: user.city,
            zip_code: user.zip_code,
            phone: user.phone,
            address: user.address || '',
            lat: user.lat || null,
            lon: user.lon || null
        });
    }, [user]);

    useEffect(() => {
        if (formData.state && user.user_type !== 'ballot_chaser') {
            setCities(citiesByState[formData.state] || []);
        } else {
            setCities([]);
        }
    }, [formData.state, user.user_type]);

    const initializeGoogleMaps = useCallback(() => {
        if (window.google && window.google.maps && user.user_type === 'ballot_chaser' && mapRef.current) {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: formData.lat || 40, lng: formData.lon || -100 },
                zoom: formData.lat && formData.lon ? 17 : 4
            });

            const marker = new window.google.maps.Marker({
                map: map,
                position: formData.lat && formData.lon ? { lat: formData.lat, lng: formData.lon } : null,
                anchorPoint: new window.google.maps.Point(0, -29)
            });

            autocompleteRef.current = new window.google.maps.places.Autocomplete(
                document.getElementById("address"),
                { types: ["address"] }
            );

            autocompleteRef.current.addListener("place_changed", () => {
                marker.setVisible(false);
                const place = autocompleteRef.current.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    console.log("No details available for input: '" + place.name + "'");
                    return;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);
                }

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);

                let address = '';
                let zipCode = '';
                let state = '';

                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            address = `${component.long_name} ${address}`;
                            break;
                        case "route":
                            address += component.short_name;
                            break;
                        case "postal_code":
                            zipCode = `${component.long_name}${zipCode}`;
                            break;
                        case "postal_code_suffix":
                            zipCode = `${zipCode}-${component.long_name}`;
                            break;
                        case "locality":
                            setFormData(prev => ({ ...prev, city: component.long_name }));
                            break;
                        case "administrative_area_level_1":
                            state = component.short_name;
                            break;
                        default:
                            break;
                    }
                }

                setFormData(prev => ({
                    ...prev,
                    address: address,
                    state: state,
                    zip_code: zipCode,
                    lat: place.geometry.location.lat(),
                    lon: place.geometry.location.lng()
                }));
            });
        }
    }, [user.user_type, formData.lat, formData.lon]);

    useEffect(() => {
        if (show && user.user_type === 'ballot_chaser' && !googleMapsLoaded) {
            const loader = new Loader({
                apiKey: "AIzaSyDKT6NsEu25cWSgppoe6ZzsJGxr6t9xbcA",
                version: "weekly",
                libraries: ["places"]
            });

            loader.load().then(() => {
                setGoogleMapsLoaded(true);
            }).catch(e => console.error("Error loading Google Maps API", e));
        }
    }, [show, user.user_type, googleMapsLoaded]);

    useEffect(() => {
        if (show && googleMapsLoaded && user.user_type === 'ballot_chaser') {
            initializeGoogleMaps();
        }
    }, [show, googleMapsLoaded, initializeGoogleMaps, user.user_type]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            if (formData.username !== user.username) {
                const isAvailable = await checkUsernameAvailability(formData.username);
                if (!isAvailable) {
                    setError("This username is already taken");
                    return;
                }
            }

            const { user: updatedUser } = await updateUser(formData);
            onUpdate(updatedUser);

            setSuccess("User information updated successfully");
        } catch (error) {
            setError(error.response?.data?.detail || 'Failed to update user information. Please try again.');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setPasswordError('');
        setPasswordSuccess('');

        if (newPassword !== confirmPassword) {
            setPasswordError("Passwords don't match");
            return;
        }

        try {
            await updatePassword(newPassword);
            setNewPassword('');
            setConfirmPassword('');
            setPasswordSuccess("Password updated successfully");
        } catch (error) {
            setPasswordError(error.response?.data?.detail || 'Failed to update password. Please try again.');
        }
    };

    const showCityZipFields = user.user_type === 'ballot_chaser';

    return (
        <Modal show={show} onClose={onClose} size="xl">
            <Modal.Header>User Settings</Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} className="space-y-4 mb-6">
                    <div>
                        <Label htmlFor="username">Username</Label>
                        <TextInput
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <Label htmlFor="email">Email</Label>
                        <TextInput
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {user.user_type === 'ballot_chaser' ? (
                        <>
                            <div>
                                <Label htmlFor="address">Address</Label>
                                <TextInput
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div ref={mapRef} style={{ height: '300px', width: '100%' }}></div>
                        </>
                    ) : (
                        <>
                            <div>
                                <Label htmlFor="state">State</Label>
                                <Select
                                    id="state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select state</option>
                                    {Object.entries(states_dict).map(([stateName, stateAbbr]) => (
                                        <option key={stateAbbr} value={stateAbbr}>{stateName} ({stateAbbr})</option>
                                    ))}
                                </Select>
                            </div>
                            {showCityZipFields && (
                                <>
                                    <div>
                                        <Label htmlFor="city">City</Label>
                                        <Select
                                            id="city"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            required
                                            disabled={!formData.state}
                                        >
                                            <option value="">Select city</option>
                                            {cities.map(city => (
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div>
                                        <Label htmlFor="zip_code">ZIP Code</Label>
                                        <TextInput
                                            id="zip_code"
                                            name="zip_code"
                                            value={formData.zip_code}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <div>
                        <Label htmlFor="phone">Phone</Label>
                        <TextInput
                            id="phone"
                            name="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    {success && <p className="text-green-500">{success}</p>}
                    <div className="flex justify-end space-x-2">
                        <Button type="submit" color="green">Update User</Button>
                    </div>
                </form>

                <form onSubmit={handlePasswordSubmit} className="space-y-4">
                    <h3 className="text-lg font-semibold">Change Password</h3>
                    <div>
                        <Label htmlFor="newPassword">New Password</Label>
                        <TextInput
                            id="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <Label htmlFor="confirmPassword">Confirm New Password</Label>
                        <TextInput
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    {passwordError && <p className="text-red-500">{passwordError}</p>}
                    {passwordSuccess && <p className="text-green-500">{passwordSuccess}</p>}
                    <div className="flex justify-end space-x-2">
                        <Button type="submit" color="blue">Change Password</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button color="gray" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsModal;