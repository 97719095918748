export const states_dict = {
  "Arizona": "AZ",
  "Georgia": "GA",
  "Michigan": "MI",
  "Nevada": "NV",
  "North Carolina": "NC",
  "Pennsylvania": "PA",
  "Wisconsin": "WI"
};

export const citiesByState = {
  "AZ": [
    "Apache Junction", "Avondale", "Bisbee", "Buckeye", "Bullhead City",
    "Casa Grande", "Chandler", "Coolidge", "Douglas", "Flagstaff",
    "Florence", "Gilbert", "Glendale", "Globe", "Goodyear",
    "Kingman", "Lake Havasu City", "Mesa", "Nogales", "Peoria",
    "Phoenix", "Prescott", "Scottsdale", "Sierra Vista", "Surprise",
    "Tempe", "Tucson", "Winslow", "Yuma"
  ],
  "GA": [
    "Albany", "Alpharetta", "Athens", "Atlanta", "Augusta",
    "Brunswick", "Columbus", "Dalton", "Decatur", "Gainesville",
    "Johns Creek", "Macon", "Marietta", "Roswell", "Sandy Springs",
    "Savannah", "Smyrna", "Valdosta", "Warner Robins"
  ],
  "MI": [
    "Ann Arbor", "Battle Creek", "Bay City", "Dearborn", "Detroit",
    "East Lansing", "Farmington Hills", "Flint", "Grand Rapids",
    "Jackson", "Kalamazoo", "Lansing", "Livonia", "Midland",
    "Muskegon", "Pontiac", "Port Huron", "Saginaw", "Sterling Heights",
    "Traverse City", "Warren"
  ],
  "NV": [
    "Boulder City", "Carson City", "Elko", "Fallon", "Fernley",
    "Henderson", "Las Vegas", "Mesquite", "North Las Vegas",
    "Reno", "Sparks", "Winnemucca"
  ],
  "NC": [
    "Asheville", "Burlington", "Cary", "Chapel Hill", "Charlotte",
    "Concord", "Durham", "Fayetteville", "Gastonia", "Greensboro",
    "Greenville", "Hickory", "High Point", "Jacksonville",
    "Kannapolis", "Raleigh", "Rocky Mount", "Wilmington", "Winston-Salem"
  ],
  "PA": [
    "Allentown", "Altoona", "Bethlehem", "Chester", "Erie",
    "Harrisburg", "Lancaster", "Philadelphia", "Pittsburgh",
    "Reading", "Scranton", "State College", "Wilkes-Barre",
    "Williamsport", "York"
  ],
  "WI": [
    "Appleton", "Eau Claire", "Fond du Lac", "Green Bay", "Janesville",
    "Kenosha", "La Crosse", "Madison", "Manitowoc", "Milwaukee",
    "Oshkosh", "Racine", "Sheboygan", "Waukesha", "Wausau", "West Allis"
  ]
};