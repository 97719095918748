import React, {useState} from 'react';
import {Button, Label, TextInput, Alert} from 'flowbite-react';

const LoginForm = ({onSubmit, error}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await onSubmit(username, password);
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <form className="p-6 pt-0" onSubmit={handleSubmit}>
            <h1 className="text-2xl font-bold mb-4">Login</h1>
            {error && <Alert color="failure" className="mb-4">{error}</Alert>}
            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="username" value="Username"/>
                </div>
                <TextInput
                    id="username"
                    type="text"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>
            <div className="mb-4">
                <div className="mb-2 block">
                    <Label htmlFor="password" value="Password"/>
                </div>
                <TextInput
                    id="password"
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <Button type="submit" size="lg" className="w-full">
                Login
            </Button>
        </form>
    );
};

export default LoginForm;