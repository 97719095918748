import React from 'react';
import { Modal, Button } from 'flowbite-react';

const PhoneModal = ({ show, onClose, phones }) => {
    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>Phone Numbers</Modal.Header>
            <Modal.Body>
                {Object.entries(phones).map(([type, number]) => (
                    <p key={type}>{`${type.charAt(0).toUpperCase() + type.slice(1)}: ${number}`}</p>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PhoneModal;