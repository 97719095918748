import React, {useState, useEffect} from 'react';
import {TextInput, Select, Button} from 'flowbite-react';
import {fetchUserTypes, fetchAutocompleteSuggestions} from '../services/api';

const FilterPanel = ({filters, setFilters, onFilterApply}) => {
    const [suggestions, setSuggestions] = useState({username: [], email: []});
    const [userTypes, setUserTypes] = useState([]);

    useEffect(() => {
        fetchUserTypes().then(setUserTypes);
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFilters(prev => ({...prev, [name]: value}));
    };

    const handleAutocomplete = async (name, value) => {
        if (value.length > 2) {
            const data = await fetchAutocompleteSuggestions(name, value);
            setSuggestions(prev => ({...prev, [name]: data}));
        }
    };

    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   onFilterApply();
    // };
    const handleSubmit = (e) => {
        e.preventDefault();
        const searchTerm = filters.username || filters.email;
        onFilterApply({
            ...filters,
            search: searchTerm,
            // Keep specific field filters as well
            username: filters.username,
            email: filters.email,
        });
    };

    return (
        <form onSubmit={handleSubmit} className="mb-4 p-4 bg-white rounded shadow">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">Username</label>
                    <TextInput
                        id="username"
                        name="username"
                        value={filters.username}
                        onChange={handleChange}
                        onKeyUp={(e) => handleAutocomplete('username', e.target.value)}
                        placeholder="Filter by username"
                        list="username-suggestions"
                    />
                    <datalist id="username-suggestions">
                        {suggestions.username.map((suggestion, index) => (
                            <option key={index} value={suggestion}/>
                        ))}
                    </datalist>
                </div>
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                    <TextInput
                        id="email"
                        name="email"
                        value={filters.email}
                        onChange={handleChange}
                        onKeyUp={(e) => handleAutocomplete('email', e.target.value)}
                        placeholder="Filter by email"
                        list="email-suggestions"
                    />
                    <datalist id="email-suggestions">
                        {suggestions.email.map((suggestion, index) => (
                            <option key={index} value={suggestion}/>
                        ))}
                    </datalist>
                </div>
                <div>
                    <label htmlFor="user_type" className="block mb-2 text-sm font-medium text-gray-900">User
                        Type</label>
                    <Select
                        id="user_type"
                        name="user_type"
                        value={filters.user_type}
                        onChange={handleChange}
                    >
                        <option value="">All Types</option>
                        {userTypes.map((type) => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="mt-4">
                <Button type="submit">Apply Filters</Button>
            </div>
        </form>
    );
};

export default FilterPanel;