import React, { useState } from 'react';
import { Sidebar } from 'flowbite-react';
import { HiChevronRight } from 'react-icons/hi';

const SidebarComponent = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="sidebar-component flex"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="bg-gray-50 border-r border-gray-200 flex items-center">
        <HiChevronRight className={`text-gray-500 text-xl transition-transform duration-300 ${isHovered ? 'rotate-180' : ''}`} />
      </div>
      <div className={`bg-white bg-opacity-95 h-full shadow-lg transition-all duration-300 overflow-hidden ${isHovered ? 'w-64' : 'w-0'}`}>
        <Sidebar aria-label="Default sidebar example" className="font-sans">
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              {[
                { href: "https://cforc.typeform.com/good-news?typeform-source=cforc.com", label: "The Good News" },
                { href: "https://cforc.com/2024/06/votersguide/", label: "Voter's Guide" },
                { href: "https://cforc.com/patrioticrosary", label: "Patriotic Rosary" },
                { href: "https://cforc.com/massesfortrump/", label: "Masses for Trump" },
                { href: "https://cforc.com/perpetual-adoration/", label: "Adoration" },
                { href: "https://cforc.com/2024/08/defend-us-in-battle-billboard-campaign/", label: "Billboards" },
                { href: "https://cforc.com/resources/", label: "Resources" },
                { href: "mailto:noreen@cforc.com,contact@cforc.com", label: "Contact Us" }
              ].map((item, index) => (
                <Sidebar.Item
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm text-gray-700 hover:text-blue-600 transition-colors duration-200"
                >
                  {item.label}
                </Sidebar.Item>
              ))}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
    </div>
  );
};

export default SidebarComponent;