import React from 'react';
import { Carousel } from 'flowbite-react';

const ScriptureBanner = () => {
  const scriptures = [
    {
      quote: "For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life.",
      reference: "John 3:16"
    },
    {
      quote: "I can do all this through him who gives me strength.",
      reference: "Philippians 4:13"
    },
    {
      quote: "Trust in the Lord with all your heart and lean not on your own understanding; in all your ways submit to him, and he will make your paths straight.",
      reference: "Proverbs 3:5-6"
    },
    {
      quote: "The Lord is my shepherd, I lack nothing. He makes me lie down in green pastures, he leads me beside quiet waters, he refreshes my soul.",
      reference: "Psalm 23:1-3"
    }
  ];

  return (
    <div className="scripture-banner">
      <Carousel slideInterval={10000} indicators={false}>
        {scriptures.map((scripture, index) => (
          <div key={index} className="carousel-item">
            <p>"{scripture.quote}" - {scripture.reference}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ScriptureBanner;