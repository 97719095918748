export const getAdvancedStatus = (statuses) => {
    if (!statuses || Object.keys(statuses).length === 0) {
        return { status: "Never Contacted", color: "failure" };
    }



    const isComplete = statuses.complete && statuses.complete.internal_status === "completed";

    if (isComplete) {
        return { status: "Complete", color: "success" };
    }


    const hasInProgress = Object.values(statuses).some(status => status.internal_status === "in_progress");

    if (hasInProgress) {
        return { status: "In Progress", color: "warning" };
    }
    return { status: "Never Contacted", color: "failure" };
};