// src/services/axiosInstance.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

export const createAxiosInstance = (onLogout) => {
    const instance = axios.create({
        baseURL: baseURL,
    });

    instance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const refreshToken = localStorage.getItem('refreshToken');
                    const response = await axios.post(`${baseURL}/api/token/refresh/`, {refresh: refreshToken});
                    const {access} = response.data;
                    localStorage.setItem('accessToken', access);
                    instance.defaults.headers.common['Authorization'] = `Bearer ${access}`;
                    return instance(originalRequest);
                } catch (refreshError) {
                    onLogout();
                    return Promise.reject(refreshError);
                }
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export default createAxiosInstance;