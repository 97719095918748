import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { login, acceptTerms } from '../services/auth';
import TermsModal from '../components/TermsModal';

const LoginPage = () => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedError = localStorage.getItem('loginError');
    console.log('Stored error on mount:', storedError);
    if (storedError) {
      setLoginError(storedError);
      localStorage.removeItem('loginError');
    }
  }, []);

  const handleLogin = async (username, password) => {
    try {
      const response = await login(username, password);
      console.log('Login response:', response);
      if (response.terms_accepted) {
        navigate('/leads');
      } else {
        setShowTermsModal(true);
      }
      setLoginError(null);
      localStorage.removeItem('loginError');
    } catch (error) {
      console.error('Login error:', error);
      const errorMessage = error.message || 'An error occurred during login';
      console.log('Setting error message:', errorMessage);
      setLoginError("Invalid username or password");
      localStorage.setItem('loginError', errorMessage);
    }
  };

  const handleAcceptTerms = async () => {
    try {
      await acceptTerms();
      setShowTermsModal(false);
      navigate('/leads');
    } catch (error) {
      console.error('Error accepting terms:', error);
      const errorMessage = 'Failed to accept terms. Please try again.';
      setLoginError(errorMessage);
      localStorage.setItem('loginError', errorMessage);
    }
  };

  console.log('Current login error:', loginError);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-96 bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6 pb-0">
          <img src="/images/logo.png" alt="Company Logo" className="w-32 h-auto mx-auto mb-4" />
        </div>
        <LoginForm onSubmit={handleLogin} error={loginError} />
      </div>
      <TermsModal
        show={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        onAccept={handleAcceptTerms}
      />
    </div>
  );
};

export default LoginPage;