import React, {useState} from 'react';
import {Modal} from 'flowbite-react';
import FilterPanel from '../components/FilterPanel';
import UsersTable from '../components/UsersTable';
import UpdateUserForm from '../components/UpdateUserForm';

const UsersPage = () => {
    const [filters, setFilters] = useState({
        username: '',
        email: '',
        user_type: '',
    });
    const [search, setSearch] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const handleFilterApply = () => {
        setSearch(filters.username || filters.email);
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setShowUpdateModal(true);
    };

    const handleUserUpdate = (updatedUser) => {
        console.log('User updated:', updatedUser);
        setShowUpdateModal(false);
        setSelectedUser(null);
        // Refresh the page after user update
        window.location.reload();
    };

    const handleUpdateCancel = () => {
        setShowUpdateModal(false);
        setSelectedUser(null);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Users</h1>
            <FilterPanel
                filters={filters}
                setFilters={setFilters}
                onFilterApply={handleFilterApply}
            />
            <UsersTable
                filters={filters}
                search={search}
                onUserSelect={handleUserSelect}
            />

            <Modal show={showUpdateModal} onClose={handleUpdateCancel}>
                <Modal.Header>Update User</Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <UpdateUserForm
                            user={selectedUser}
                            onUpdate={handleUserUpdate}
                            onCancel={handleUpdateCancel}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UsersPage;