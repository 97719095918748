import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'flowbite-react';
import { fetchUserData } from '../services/api';
import SettingsModal from './SettingsModal';
import SidebarComponent from './SidebarComponent';
// import MessagesIcon from './MessagesIcon';
import StateMetadataModal from './StateMetadataModal';
import { FaUserCircle } from 'react-icons/fa';
// import NewsContainer from "./NewsContainer";
import ScriptureBanner from "./ScriptureBanner";

const userTypeMapping = {
    admin: "Admin",
    national_director: "National Director",
    state_director: "State Director",
    ballot_chaser: "Ballot Chaser"
};

const Layout = ({ handleLogout }) => {
    const [user, setUser] = useState({});
    const [showSettings, setShowSettings] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const getUserData = async () => {
            try {
                const data = await fetchUserData();
                console.log('User data fetched:', data);
                setUser(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        getUserData();
    }, []);

    const isActive = (path) => {
        return location.pathname === path;
    };

    const handleSettingsClick = () => {
        setShowSettings(true);
    };

    const handleSettingsClose = () => {
        setShowSettings(false);
        navigate(0);
    };

    const handleUserUpdate = (updatedUser) => {
        setUser(prevUser => ({...prevUser, ...updatedUser}));
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="min-h-screen bg-gray-100 font-sans pb-10">
            <ScriptureBanner />
            <nav className="custom-navbar">
                <div className="custom-navbar-content">
                    <div className="flex items-center">
                        <button onClick={toggleMenu} className="md:hidden mr-2 custom-navbar-mobile-menu-button">
                            <span className="sr-only">Open main menu</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                        <div className="flex-shrink-0 flex items-center">
                            <img className="h-8 w-auto" src="/images/logo.png" alt="Logo" />
                            <span className="ml-2 text-xl font-semibold">Campaign Management</span>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="hidden md:flex items-center space-x-4 mr-4">
                            <Link to="/leads" className={`custom-navbar-link ${isActive('/leads') ? 'custom-navbar-link-active' : 'custom-navbar-link-inactive'}`}>Leads</Link>
                            {(user.user_type === 'admin' || user.user_type === 'national_director' || user.user_type === 'state_director') && (
                                <>
                                    <Link to="/create-user" className={`custom-navbar-link ${isActive('/create-user') ? 'custom-navbar-link-active' : 'custom-navbar-link-inactive'}`}>Create User</Link>
                                    <Link to="/users" className={`custom-navbar-link ${isActive('/users') ? 'custom-navbar-link-active' : 'custom-navbar-link-inactive'}`}>Users</Link>
                                </>
                            )}
                        </div>

                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={
                                <FaUserCircle className="h-8 w-8 text-gray-500" />
                            }
                        >
                            <Dropdown.Header>
                                <span className="block text-sm">{user.username}</span>
                                <span className="block truncate text-sm font-medium">{user.email}</span>
                                <Dropdown.Divider/>
                                <span className="block text-sm">{userTypeMapping[user.user_type]}</span>
                                <span className="block text-sm">{user.state}</span>
                                <span className="block text-sm">{user.city}</span>
                                <span className="block text-sm">{user.zip_code}</span>
                            </Dropdown.Header>
                            <Dropdown.Item onClick={handleSettingsClick}>Settings</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>

                <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden custom-navbar-mobile-menu-content`}>
                    <Link to="/leads" className={`custom-navbar-link block ${isActive('/leads') ? 'custom-navbar-link-active' : 'custom-navbar-link-inactive'}`}>Leads</Link>
                    {(user.user_type === 'admin' || user.user_type === 'national_director' || user.user_type === 'state_director') && (
                        <>
                            <Link to="/create-user" className={`custom-navbar-link block ${isActive('/create-user') ? 'custom-navbar-link-active' : 'custom-navbar-link-inactive'}`}>Create User</Link>
                            <Link to="/users" className={`custom-navbar-link block ${isActive('/users') ? 'custom-navbar-link-active' : 'custom-navbar-link-inactive'}`}>Users</Link>
                        </>
                    )}
                </div>
            </nav>

            <div className="layout-container pt-16">
                {/*<div className="container mx-auto px-4 sm:px-6 lg:px-6">*/}
                {/*    <NewsContainer />*/}
                {/*</div>*/}
                <div className="flex">
                    <SidebarComponent />
                    <div className="main-content flex-1 p-4">
                        <Outlet />
                    </div>
                </div>
            </div>

            <SettingsModal
                show={showSettings}
                onClose={handleSettingsClose}
                user={user}
                onUpdate={handleUserUpdate}
            />
            {/*<MessagesIcon onClick={() => setShowMessages(true)} />*/}
            <StateMetadataModal
                show={showMessages}
                onClose={() => setShowMessages(false)}
                userType={user.user_type}
                userState={user.state}
            />
        </div>
    );
};

export default Layout;