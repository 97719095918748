import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Label, Textarea } from 'flowbite-react';

const EditStatusForm = ({ status, availableStatuses, isEditing, onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        status: '',
        internal_status: '',
        notes: '',
    });

    useEffect(() => {
        if (status) {
            setFormData({
                status: status.status,
                internal_status: status.internal_status,
                notes: status.notes,
            });
        } else {
            setFormData({
                status: availableStatuses[0],
                internal_status: 'in_progress',
                notes: '',
            });
        }
    }, [status, availableStatuses]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <Modal show={true} onClose={onCancel}>
            <Modal.Header>{isEditing ? 'Edit Status' : 'Add New Status'}</Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="status" value="Status" />
                        </div>
                        {isEditing ? (
                            <Select
                                id="status"
                                name="status"
                                value={formData.status}
                                disabled
                            >
                                <option value={formData.status}>
                                    {formData.status.charAt(0).toUpperCase() + formData.status.slice(1).replace('_', ' ')}
                                </option>
                            </Select>
                        ) : (
                            <Select
                                id="status"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                                required
                            >
                                {availableStatuses.map(status => (
                                    <option key={status} value={status}>
                                        {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                                    </option>
                                ))}
                            </Select>
                        )}
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="internal_status" value="Internal Status" />
                        </div>
                        <Select
                            id="internal_status"
                            name="internal_status"
                            value={formData.internal_status}
                            onChange={handleChange}
                            required
                        >
                            <option value="in_progress">In Progress</option>
                            <option value="completed">Completed</option>
                        </Select>
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="notes" value="Notes" />
                        </div>
                        <Textarea
                            id="notes"
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            placeholder="Enter notes here..."
                            rows={4}
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
                        <Button onClick={onCancel} color="gray">Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditStatusForm;