import React from 'react';
import {Pagination, Button} from 'flowbite-react';

const PaginationSection = ({
                               currentPage,
                               totalPages,
                               onPageChange,
                               userType,
                               selectedRows,
                               filters,
                               openAssignModal
                           }) => {
    return (
        <div className="flex justify-between mt-4">
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
            {userType !== 'ballot_chaser' && (
                <Button
                    onClick={openAssignModal}
                    disabled={selectedRows.length === 0 || filters.assigned_to !== 'none'}
                >
                    Assign Selected Leads
                </Button>
            )}
        </div>
    );
};

export default PaginationSection;