import React from 'react';
import { Table, Checkbox, Button, Badge } from 'flowbite-react';
import { FaPhone, FaUser, FaMapMarkerAlt } from 'react-icons/fa';
import { getAdvancedStatus } from '../utils/statusHelpers';

const TableRow = ({
    lead,
    userType,
    selectedRows,
    setSelectedRows,
    checkboxesLocked,
    openPhoneModal,
    openStatusModal,
    openBallotChaserModal,
    openAddressModal,
    onStatusUpdate
}) => {
    const { status, color } = getAdvancedStatus(lead.statuses);

    const handleRowSelect = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter(rowId => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            {userType !== 'ballot_chaser' && (
                <Table.Cell className="p-4">
                    <Checkbox
                        checked={selectedRows.includes(lead.id)}
                        onChange={() => handleRowSelect(lead.id)}
                        disabled={checkboxesLocked}
                    />
                </Table.Cell>
            )}
            <Table.Cell>{lead.first_name}</Table.Cell>
            <Table.Cell>{lead.last_name}</Table.Cell>
            <Table.Cell>{lead.residence_state}</Table.Cell>
            <Table.Cell>{lead.residence_city}</Table.Cell>
            <Table.Cell>{lead.residence_address_zip}</Table.Cell>
            <Table.Cell>
                <Button size="sm" onClick={() => openAddressModal(lead.residence_address)}>
                    <FaMapMarkerAlt className="text-white"/>
                </Button>
            </Table.Cell>
            <Table.Cell>
                <Button size="sm" onClick={() => openPhoneModal(lead.phone_numbers)}>
                    <FaPhone className="text-white"/>
                </Button>
            </Table.Cell>
            <Table.Cell>
                {lead.assigned_to ? (
                    <Badge
                        color="info"
                        className="cursor-pointer inline-block text-center py-2 px-3 rounded-full text-sm font-medium"
                        onClick={() => openBallotChaserModal(lead.assigned_to)}
                    >
                        <FaUser className="inline-block mr-1"/>
                        {lead.assigned_to.username}
                    </Badge>
                ) : (
                    <Badge color="gray" className="inline-block text-center py-2 px-3 rounded-full text-sm font-medium">
                        Unassigned
                    </Badge>
                )}
            </Table.Cell>
            <Table.Cell>
                <Badge
                    color={color}
                    className="cursor-pointer inline-block text-center py-2 px-3 rounded-full text-sm font-medium"
                    onClick={() => openStatusModal(lead.id)}
                >
                    {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                </Badge>
            </Table.Cell>
        </Table.Row>
    );
};

export default TableRow;