import React from 'react';
import {Table, Checkbox, Tooltip} from 'flowbite-react';
import {FaInfoCircle} from 'react-icons/fa';

const TableHeader = ({userType, selectAll, setSelectAll, checkboxesLocked}) => {
    return (
        <Table.Head>
            {userType !== 'ballot_chaser' && (
                <Table.HeadCell className="p-4">
                    <div className="flex items-center">
                        <Checkbox
                            checked={selectAll}
                            onChange={() => setSelectAll(!selectAll)}
                            disabled={checkboxesLocked}
                        />
                        {checkboxesLocked && (
                            <Tooltip content="Filter by unassigned leads to enable selection" className="normal-case">
                                <FaInfoCircle className="ml-2 text-gray-500"/>
                            </Tooltip>
                        )}
                    </div>
                </Table.HeadCell>
            )}
            <Table.HeadCell>First Name</Table.HeadCell>
            <Table.HeadCell>Last Name</Table.HeadCell>
            <Table.HeadCell>State</Table.HeadCell>
            <Table.HeadCell>City</Table.HeadCell>
            <Table.HeadCell>ZIP Code</Table.HeadCell>
            <Table.HeadCell>Address</Table.HeadCell>
            <Table.HeadCell>Phone</Table.HeadCell>
            <Table.HeadCell>Ballot Chaser</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
        </Table.Head>
    );
};

export default TableHeader;