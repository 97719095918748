import React from 'react';
import {Modal, Button} from 'flowbite-react';
import {HiCheckCircle} from 'react-icons/hi';

const ConfirmationModal = ({show, onClose, message}) => {
    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>
                <div className="flex items-center">
                    <HiCheckCircle className="mr-2 h-5 w-5 text-green-500"/>
                    Assignment Confirmation
                </div>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;