import React, {useState, useEffect, useMemo} from 'react';
import {Modal, Button, Accordion, Badge} from 'flowbite-react';
import {HiClock} from 'react-icons/hi';
import {fetchLeadStatuses, createLeadStatus, updateLeadStatus, deleteLeadStatus} from '../services/api';
import EditStatusForm from './EditStatusForm';

const StatusModal = ({show, onClose, leadId, onStatusUpdate}) => {
    const [statuses, setStatuses] = useState([]);
    const [editingStatus, setEditingStatus] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [availableStatuses, setAvailableStatuses] = useState([]);

    const STATUS_ORDER = useMemo(() => ({
        "text": 1,
        "phone_call": 2,
        "postcard": 3,
        "visit": 4,
        "early_voting": 5,
        "complete": 6,
    }), []);

    const allStatuses = useMemo(() => Object.keys(STATUS_ORDER), [STATUS_ORDER]);

    useEffect(() => {
        if (show && leadId) {
            fetchLeadStatuses(leadId)
                .then(data => {
                    const sortedStatuses = data.sort((a, b) => STATUS_ORDER[a.status] - STATUS_ORDER[b.status]);
                    setStatuses(sortedStatuses);
                    const existingStatuses = new Set(data.map(status => status.status));
                    setAvailableStatuses(allStatuses.filter(status => !existingStatuses.has(status)));
                })
                .catch(error => console.error('Error fetching statuses:', error));
        }
    }, [show, leadId, allStatuses, STATUS_ORDER]);

    const handleStatusSubmit = async (statusData) => {
        try {
            if (isAddingNew) {
                await createLeadStatus({...statusData, lead: leadId});
            } else if (editingStatus) {
                await updateLeadStatus(editingStatus.id, statusData);
            }
            const updatedStatuses = await fetchLeadStatuses(leadId);
            const sortedStatuses = updatedStatuses.sort((a, b) => STATUS_ORDER[a.status] - STATUS_ORDER[b.status]);
            setStatuses(sortedStatuses);
            const existingStatuses = new Set(updatedStatuses.map(status => status.status));
            setAvailableStatuses(allStatuses.filter(status => !existingStatuses.has(status)));
            setEditingStatus(null);
            setIsAddingNew(false);
            onStatusUpdate();
        } catch (error) {
            console.error('Error submitting status:', error);
        }
    };

    const handleDeleteStatus = async (statusId) => {
        try {
            await deleteLeadStatus(statusId);
            const updatedStatuses = await fetchLeadStatuses(leadId);
            const sortedStatuses = updatedStatuses.sort((a, b) => STATUS_ORDER[a.status] - STATUS_ORDER[b.status]);
            setStatuses(sortedStatuses);
            const existingStatuses = new Set(updatedStatuses.map(status => status.status));
            setAvailableStatuses(allStatuses.filter(status => !existingStatuses.has(status)));
            onStatusUpdate();
        } catch (error) {
            console.error('Error deleting status:', error);
        }
    };

    // const formatDate = (dateString) => {
    //     const options = {year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
    //     return new Date(dateString).toLocaleDateString(undefined, options);
    // };

    const getStatusBadge = (internalStatus) => {
        if (internalStatus === 'completed') {
            return <Badge color="success">Completed</Badge>;
        }
        return <Badge color="warning">In Progress</Badge>;
    };

    return (
        <Modal show={show} onClose={onClose} size="xl">
            <Modal.Header>Lead Status Details</Modal.Header>
            <Modal.Body>
                <Accordion collapseAll>
                    {statuses.map((status) => (
                        <Accordion.Panel key={status.id}>
                            <Accordion.Title>
                                <div className="flex justify-between items-center w-full">
                                    <div className="pr-4">
                                        <span>
                                            {status.status.charAt(0).toUpperCase() + status.status.slice(1).replace('_', ' ')}
                                        </span>
                                    </div>
                                    <div>
                                        {getStatusBadge(status.internal_status)}
                                    </div>
                                    <div className="pl-2 flex items-center">
                                        <HiClock className="mr-1 text-gray-500"/>
                                        <span className="text-xs text-gray-500">
                                            Last update: {status.formatted_updated_at}
                                        </span>
                                    </div>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content>
                                <div className="mb-4 p-4 border rounded">
                                    <p><strong>Notes:</strong><br/>
                                        <span style={{whiteSpace: 'pre-line'}}>
                                            {status.notes || 'No notes'}
                                        </span>
                                    </p>
                                </div>
                                <div className="mt-2 flex space-x-2">
                                    <Button size="sm" onClick={() => setEditingStatus(status)}>Edit</Button>
                                    <Button size="sm" color="failure"
                                            onClick={() => handleDeleteStatus(status.id)}>Delete</Button>
                                </div>
                            </Accordion.Content>
                        </Accordion.Panel>
                    ))}
                </Accordion>
                {statuses.length === 0 && (
                    <p>No status information available.</p>
                )}
                {availableStatuses.length > 0 && (
                    <Button onClick={() => setIsAddingNew(true)} className="mt-4">Add New Status</Button>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
            {(editingStatus || isAddingNew) && (
                <EditStatusForm
                    status={editingStatus}
                    availableStatuses={availableStatuses}
                    isEditing={!!editingStatus}
                    onSubmit={handleStatusSubmit}
                    onCancel={() => {
                        setEditingStatus(null);
                        setIsAddingNew(false);
                    }}
                />
            )}
        </Modal>
    );
};

export default StatusModal;