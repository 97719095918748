import React from 'react';
import LeadsTable from '../components/LeadsTable';

const LeadsPage = () => {
    return (
        <div>
            <LeadsTable/>
        </div>
    );
};

export default LeadsPage;
