import React from 'react';
import { Modal, Button } from 'flowbite-react';

const BallotChaserModal = ({ show, onClose, ballotChaser }) => {
    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>Ballot Chaser Information</Modal.Header>
            <Modal.Body>
                {ballotChaser && (
                    <>
                        <p><strong>Name:</strong> {ballotChaser.username}</p>
                        <p><strong>Email:</strong> {ballotChaser.email}</p>
                        <p><strong>State:</strong> {ballotChaser.state}</p>
                        <p><strong>City:</strong> {ballotChaser.city}</p>
                        <p><strong>ZIP Code:</strong> {ballotChaser.zip_code}</p>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BallotChaserModal;