import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Checkbox, Label, Select, Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';
import { fetchUsers, assignLeads } from '../services/api';

const AssignModal = ({ show, onClose, selectedRows, leads, onAssignSuccess }) => {
    const [ballotChasers, setBallotChasers] = useState([]);
    const [selectedChaser, setSelectedChaser] = useState('');
    const [showOtherCityChasers, setShowOtherCityChasers] = useState(false);

    const fetchBallotChasers = useCallback(async (state, city) => {
        try {
            const data = await fetchUsers(1, {
                user_type: 'ballot_chaser',
                state,
                city: showOtherCityChasers ? '' : city
            });
            setBallotChasers(data.results);
        } catch (error) {
            console.error('Error fetching ballot chasers:', error);
        }
    }, [showOtherCityChasers]);

    useEffect(() => {
        if (show && selectedRows.length > 0) {
            const selectedLead = leads.find(lead => lead.id === selectedRows[0]);
            fetchBallotChasers(selectedLead.residence_state, selectedLead.residence_city);
        }
    }, [show, selectedRows, leads, fetchBallotChasers]);

    const handleShowOtherCityChasersChange = (e) => {
        setShowOtherCityChasers(e.target.checked);
        const selectedLead = leads.find(lead => lead.id === selectedRows[0]);
        fetchBallotChasers(selectedLead.residence_state, e.target.checked ? '' : selectedLead.residence_city);
    };

    const handleAssign = async () => {
        if (selectedChaser) {
            try {
                await assignLeads(selectedRows, selectedChaser);
                const assignedChaser = ballotChasers.find(c => c.id.toString() === selectedChaser);
                onAssignSuccess(`Successfully assigned ${selectedRows.length} lead(s) to ${assignedChaser.username}.`);
                onClose();
            } catch (error) {
                console.error('Error assigning leads:', error);
                onAssignSuccess('An error occurred while assigning leads. Please try again.');
            }
        }
    };

    return (
        <Modal show={show} onClose={onClose}>
            <Modal.Header>Assign Leads</Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <Checkbox
                        id="showOtherCityChasers"
                        checked={showOtherCityChasers}
                        onChange={handleShowOtherCityChasersChange}
                    />
                    <Label htmlFor="showOtherCityChasers" className="ml-2">
                        Show ballot chasers from other cities in the same state
                    </Label>
                </div>
                {ballotChasers.length > 0 ? (
                    <>
                        <Select onChange={(e) => setSelectedChaser(e.target.value)} value={selectedChaser}>
                            <option value="">Select a Ballot Chaser</option>
                            {ballotChasers.map((chaser) => (
                                <option key={chaser.id} value={chaser.id}>
                                    {`${chaser.username} - ${chaser.state}, ${chaser.city} (${chaser.zip_code})`}
                                </option>
                            ))}
                        </Select>
                        {selectedChaser && (
                            <div className="mt-4 p-4 border rounded">
                                <h4 className="font-bold text-lg mb-2">Selected Ballot Chaser:</h4>
                                {(() => {
                                    const chaser = ballotChasers.find(c => c.id.toString() === selectedChaser);
                                    return (
                                        <>
                                            <p><strong>Name:</strong> {chaser.username}</p>
                                            <p><strong>State:</strong> {chaser.state}</p>
                                            <p><strong>City:</strong> {chaser.city}</p>
                                            <p><strong>ZIP Code:</strong> {chaser.zip_code}</p>
                                        </>
                                    );
                                })()}
                            </div>
                        )}
                    </>
                ) : (
                    <Alert color="failure" icon={HiInformationCircle}>
                        <span className="font-medium">No ballot chasers available!</span>
                        <br/>
                        <span className="font-medium">Try including ballot chasers from other cities.</span>
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleAssign} disabled={!selectedChaser}>Assign</Button>
                <Button color="gray" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignModal;