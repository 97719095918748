import {createAxiosInstance} from './axiosInstance';

const axiosInstance = createAxiosInstance();

export const fetchLeads = async (filters, page = 1) => {
    try {
        const params = new URLSearchParams({...filters, page}).toString();
        const response = await axiosInstance.get(`/api/leads/?${params}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching leads:', error);
        return {results: [], count: 0, next: null, previous: null};
    }
};

export const fetchFilterOptions = async (filterType) => {
    try {
        const response = await axiosInstance.get(`/api/leads/filter-options/?filter_type=${filterType}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching filter options:', error);
        return {};
    }
};

export const fetchAutocomplete = async (field, query, filters) => {
    try {
        const params = new URLSearchParams({field, query, ...filters});
        const response = await axiosInstance.get(`/api/leads/autocomplete/?${params}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
        return [];
    }
};
export const fetchUserData = async () => {
    try {
        const response = await axiosInstance.get('/api/users/me');
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = '/login';
        }
        console.error('Error fetching user data:', error);
        return {user_type: '', username: '', email: '', state: '', city: '', zip_code: '', terms_accepted: false};
    }
};


// src/services/api.js
export const assignLeads = async (leadIds, ballotChaserId) => {
    try {
        const response = await axiosInstance.post('/api/leads/assign-leads/', {
            lead_ids: leadIds,
            ballot_chaser_id: ballotChaserId,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = '/login';
        }
        console.error('Error assigning leads:', error);
        throw error;
    }
};

export const createUser = async (userData) => {
    try {
        const response = await axiosInstance.post('/api/users/', userData);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = '/login';
        }
        console.error('Error creating user:', error);
        throw error;
    }
};

export const fetchUsers = async (page = 1, filters = {}, search = '') => {
    try {
        const params = new URLSearchParams({page: page.toString(), search});
        Object.entries(filters).forEach(([key, value]) => {
            if (value) params.append(key, value.toString());
        });
        const response = await axiosInstance.get(`/api/users/?${params.toString()}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        return {results: [], count: 0, next: null, previous: null};
    }
};

export const fetchUserTypes = async () => {
    try {
        const response = await axiosInstance.get('/api/users/user_types/');
        return response.data;
    } catch (error) {
        console.error('Error fetching user types:', error);
        return [];
    }
};


export const fetchAutocompleteSuggestions = async (field, query) => {
    try {
        const response = await axiosInstance.get(`/api/users/autocomplete/?field=${field}&query=${query}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
        return [];
    }
};

export const assignNearestLeads = async (ballotChaserId, numLeads) => {
    try {
        const response = await axiosInstance.post('/api/leads/assign-nearest/', {
            ballot_chaser_id: ballotChaserId,
            num_leads: numLeads
        });
        return response.data;
    } catch (error) {
        console.error('Error assigning nearest leads:', error);
        throw error;
    }
};


export const fetchLeadStatuses = async (leadId) => {
    try {
        const response = await axiosInstance.get(`/api/leads/${leadId}/statuses/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching lead statuses:', error);
        throw error;
    }
};


export const updateLeadStatus = async (statusId, statusData) => {
    try {
        console.log('Updating status:', statusId, 'with data:', statusData);
        const response = await axiosInstance.put(`/api/lead-statuses/${statusId}/`, statusData);
        console.log('Update response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating lead status:', error.response?.data || error.message);
        throw error;
    }
};


export const updateUserDetails = async (userId, userData) => {
    try {
        const response = await axiosInstance.patch(`/api/users/${userId}/`, userData);
        return response.data;
    } catch (error) {
        console.error('Error updating user details:', error);
        throw error;
    }
};

export const createLeadStatus = async (statusData) => {
    try {
        const response = await axiosInstance.post('/api/lead-statuses/', statusData);
        return response.data;
    } catch (error) {
        console.error('Error creating lead status:', error);
        throw error;
    }
};

export const deleteLeadStatus = async (statusId) => {
    try {
        await axiosInstance.delete(`/api/lead-statuses/${statusId}/`);
    } catch (error) {
        console.error('Error deleting lead status:', error);
        throw error;
    }
};

export const updateUsername = async (newUsername) => {
    try {
        const response = await axiosInstance.patch('/api/users/update_settings/', {username: newUsername});
        return response.data;
    } catch (error) {
        console.error('Error updating username:', error);
        throw error;
    }
};

export const updatePassword = async (newPassword) => {
    try {
        const response = await axiosInstance.patch('/api/users/update_settings/', {new_password: newPassword});
        return response.data;
    } catch (error) {
        console.error('Error updating password:', error);
        throw error;
    }
};

export const checkUsernameAvailability = async (username) => {
    try {
        const response = await axiosInstance.get(`/api/users/check_username/?username=${username}`);
        return response.data.available;
    } catch (error) {
        console.error('Error checking username availability:', error);
        throw error;
    }
};

export const updateMaxLeads = async (userId, newMaxLeads) => {
    try {
        const response = await axiosInstance.patch(`/api/users/${userId}/update-max-leads/`, {
            max_number_of_leads: newMaxLeads
        });
        return response.data;
    } catch (error) {
        console.error('Error updating max leads:', error);
        throw error;
    }
};

export const checkNewMessages = async () => {
    try {
        const response = await axiosInstance.get('/api/state-metadata/check_new_messages/');
        return response.data;
    } catch (error) {
        console.error('Error checking new messages:', error);
        throw error;
    }
};

export const fetchStateMetadata = async () => {
    try {
        const response = await axiosInstance.get('/api/state-metadata/');
        return response.data;
    } catch (error) {
        console.error('Error fetching state metadata:', error);
        throw error;
    }
};

export const createStateMetadata = async (data) => {
    try {
        const response = await axiosInstance.post('/api/state-metadata/', data);
        return response.data;
    } catch (error) {
        console.error('Error creating state metadata:', error);
        throw error;
    }
};

export const updateStateMetadata = async (id, data) => {
    try {
        const response = await axiosInstance.put(`/api/state-metadata/${id}/`, data);
        return response.data;
    } catch (error) {
        console.error('Error updating state metadata:', error);
        throw error;
    }
};

export const deleteStateMetadata = async (id) => {
    try {
        await axiosInstance.delete(`/api/state-metadata/${id}/`);
    } catch (error) {
        console.error('Error deleting state metadata:', error);
        throw error;
    }
};

export const updateUser = async (userData) => {
    try {
        const response = await axiosInstance.patch('/api/users/update_settings/', userData);
        return response.data;
    } catch (error) {
        console.error('Error updating user settings:', error);
        throw error;
    }
};
