import React, { useState, useEffect } from 'react';
import { Modal, Button, Label, Select, Table } from 'flowbite-react';
import { assignNearestLeads, fetchUsers } from '../services/api';

const AssignNearestLeadsModal = ({ show, onClose, onAssignSuccess }) => {
    const [ballotChasers, setBallotChasers] = useState([]);
    const [selectedChaser, setSelectedChaser] = useState('');
    const [numLeads, setNumLeads] = useState(10);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [assignedLeads, setAssignedLeads] = useState([]);
    const [isAssigned, setIsAssigned] = useState(false);

    useEffect(() => {
        if (show) {
            fetchBallotChasers();
            setIsAssigned(false);
            setAssignedLeads([]);
            setError('');
        }
    }, [show]);

    const fetchBallotChasers = async () => {
        try {
            const data = await fetchUsers(1, { user_type: 'ballot_chaser' });
            setBallotChasers(data.results);
        } catch (error) {
            console.error('Error fetching ballot chasers:', error);
            setError('Failed to fetch ballot chasers. Please try again.');
        }
    };

    const handleAssign = async () => {
        setLoading(true);
        setError('');
        try {
            const result = await assignNearestLeads(selectedChaser, numLeads);
            setAssignedLeads(result.assigned_leads);
            setIsAssigned(true);
            onAssignSuccess(`${result.assigned_leads.length} leads assigned to ballot chaser.`, result.assigned_leads);
        } catch (error) {
            setError('Failed to assign leads. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onClose={onClose} size="xl">
            <Modal.Header>Assign Nearest Leads to Ballot Chaser</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <div>
                        <Label htmlFor="ballotChaser" value="Select Ballot Chaser" />
                        <Select
                            id="ballotChaser"
                            value={selectedChaser}
                            onChange={(e) => setSelectedChaser(e.target.value)}
                            required
                            disabled={isAssigned}
                        >
                            <option value="">Select a Ballot Chaser</option>
                            {ballotChasers.map((chaser) => (
                                <option key={chaser.id} value={chaser.id}>
                                    {`${chaser.username} - ${chaser.state}, ${chaser.city} (${chaser.zip_code})`}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <Label htmlFor="numLeads" value="Number of Leads" />
                        <Select
                            id="numLeads"
                            value={numLeads}
                            onChange={(e) => setNumLeads(Number(e.target.value))}
                            disabled={isAssigned}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                        </Select>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    {assignedLeads.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2">Assigned Leads:</h3>
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>Name</Table.HeadCell>
                                    <Table.HeadCell>Address</Table.HeadCell>
                                    <Table.HeadCell>City</Table.HeadCell>
                                    <Table.HeadCell>State</Table.HeadCell>
                                    <Table.HeadCell>Zip Code</Table.HeadCell>
                                </Table.Head>
                                <Table.Body>
                                    {assignedLeads.map((lead) => (
                                        <Table.Row key={lead.id}>
                                            <Table.Cell>{`${lead.first_name} ${lead.last_name}`}</Table.Cell>
                                            <Table.Cell>{lead.residence_address}</Table.Cell>
                                            <Table.Cell>{lead.residence_city}</Table.Cell>
                                            <Table.Cell>{lead.residence_state}</Table.Cell>
                                            <Table.Cell>{lead.residence_address_zip}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!isAssigned && (
                    <Button onClick={handleAssign} disabled={loading || !selectedChaser}>
                        {loading ? 'Assigning...' : 'Assign Leads'}
                    </Button>
                )}
                <Button color="gray" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignNearestLeadsModal;